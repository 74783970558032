export const environment = {
	production: true,
	hmr: false,

	webUri: 'https://portal.tagion.com',
	apiUri: 'https://api-saft.decard.io',
  apiUriContactSupport: 'https://i25scrm.azurewebsites.net/api',
  payoutServiceSocketUrl: 'https://payout.decard.io',

	appVersion: require('../../package.json').version,

  investorRelationsEmail: 'ir@decard.io',
  supportEmail: 'clientsupport@decard.io',

  websocketApiKey: process.env.WS_API_KEY,

	firebaseConfig: {
		apiKey: process.env.FIREBASE_API_KEY, 
		authDomain: process.env.FIREBASE_AUTH_DOMAIN,
		projectId: process.env.FIREBASE_PROJECT_ID,
		storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
		messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
		appId: process.env.FIREBASE_APP_ID,
		measurementId: process.env.FIREBASE_MEASUREMENT_ID,
		FIREBASE_TOKEN: process.env.FIREBASE_TOKEN,
	},
};
